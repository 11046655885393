<template>

  <div>
     <!-- Tambah Data -->
    <div>
      <b-modal id="buat_panel_jadwal" size="lg" title="Panel Jadwal" ref="btnClose" hide-footer>
      <tambah-data
        @refetchData="refetchData"
        @emitPilihInstansi="pilihInstansi"
        @emitCloseTambahData="closeTambahData"
        @closeTambahData="closeTambahData"
        :timPanelOptions="timPanelOptions.options"
        :timAhliOptions="timAhliOptions.options"
       />
      </b-modal>
    </div>

    <!-- Filters -->
    <!-- <list-filters
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <b-row class="mb-5">
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <h5>PERSIDANGAN - JADWAL PANEL</h5>
            </b-col>
          
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
            >
              <b-dropdown id="dropdown-1" text="Export" variant="primary" class="m-md-2">
                <b-dropdown-item><feather-icon icon="FileIcon" /> Excel</b-dropdown-item>
              </b-dropdown>
            </b-col>
        </b-row>

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
                    <!-- <b-button class="btn mb-2" v-b-modal.buat_panel_jadwal variant="outline-primary" v-if="$can('manage','all')">Buat Panel Jadwal</b-button> -->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchDatas"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(status)="data">
          <b-media vertical-align="center">
              <b-badge pill :variant="`light-warning`" v-if="data.item.status === null || data.item.status === ''">Not Set</b-badge>
              <b-badge pill :variant="`light-success`" v-if="data.item.status === 'hadir'">Hadir</b-badge>
              <b-badge pill :variant="`light-danger`" v-if="data.item.status === 'tidak hadir'">Tidak Hadir</b-badge>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
            <div class="d-flex">
              <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="outline-primary" @click="detailJadwalPanel(data.item.id, data.item.laporan_selected)" title="Status">
                <feather-icon icon="SearchIcon" />
              </b-button>
              <b-modal :id="`detail_panel_jadwal_${data.item.id}`" size="lg" title="Panel Jadwal" ref="btnClose" hide-footer>
              <detail 
                :tenagaAhliOption="tenagaAhliOption.options"
                :penyajiOption="penyajiOption.options"
                :timPemeriksaOption="timPemeriksaOption.options"
                :komisionerOption="komisionerOption.options"
                :selectedCheckbox="data.item.laporan_selected"
                :dataJadwalSidangPanel="dataJadwalSidangPanel"
                :dataDetailJadwalPanel="data.item"
              />
              </b-modal>
              <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="outline-primary" title="Edit Panel Jadwal" :to="{ name: 'panel-jadwal-edit', params: { id: data.item.id } }">
                <feather-icon icon="EditIcon" />
              </b-button>
              <!-- <b-button class="mr-1 btn-icon btn btn-relief-primary" variant="outline-danger" title="Batalkan Panel Jadwal" @click="deleteData(data.item.id, data.item.no_register)">
                <feather-icon icon="XCircleIcon" />
              </b-button> -->
            </div>
            <!--  -->
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Menampilkan {{ dataMeta.from }} per {{ dataMeta.to }} dari {{ dataMeta.of }} data </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BModal, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/connection-api/jadwal-panel/storeModule'
import useList from '@/connection-api/jadwal-panel/list'
import TambahData from './TambahData.vue'
import EditData from './EditData.vue'
import listFilters from './ListFilters.vue'
import Detail from './Detail.vue'
import {
  deletePanelJadwal,
  getTimPanel,
  getTimAhli,
  getDataBuatJadwalSidangPanel,
} from '@/connection-api/master'
// import {
//   pilihUnitUtama,
// } from '@/connection-api/kerjasama'
// import {
//   undangUnitUtama,
// } from '@/connection-api/jadwal-pembahasan'

export default {
  components: {
    listFilters,
    TambahData,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    EditData,
    vSelect,
    BModal,
    BFormGroup,
    BFormTextarea,
    BBadge,
    Detail,
  },
  data() {
    return {
      counter: 1,
      instansiId: null,
      showTambahData: false,
      showEditData: false,
      negaraOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Negara --' },
        ],
      },
      provinsiOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Provinsi --' },
        ],
      },
      kotaOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kota --' },
        ],
      },
      timPanelOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih TIm Panel --' },
        ],
      },
      timAhliOptions: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih TIm Ahli --' },
        ],
      },
      tenagaAhliOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Tenaga Ahli --' },
        ],
      },
      penyajiOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Penyaji --' },
        ],
      },
      timPemeriksaOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Tim Pemeriksa --' },
        ],
      },
      komisionerOption: {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Nama --' },
        ],
      },
      dataJadwalSidangPanel: {},
    }
  },
  async mounted() {
    const respTimPanel = await getTimPanel()
    this.mappingSelectOption(respTimPanel.data, this.timPanelOptions.options)

    const respTimAhli = await getTimAhli()
    this.mappingSelectOption(respTimAhli.data, this.timAhliOptions.options)
  },
  props: {
    pihak: {},
    jadwal_pembahasan_id: {},
  },
  methods: {
    async detailJadwalPanel(id, selectedLaporan) {
      const response = await getDataBuatJadwalSidangPanel(selectedLaporan)
        if(response.data.success == true) {
          this.tenagaAhliOption = {
            selected: 'id',
            options: [
              { value: null, label: '--Pilih Tenaga Ahli --' },
            ],
          }
          this.penyajiOption = {
            selected: 'id',
            options: [
              { value: null, label: '--Pilih Penyaji --' },
            ],
          }
          this.timPemeriksaOption = {
            selected: 'id',
            options: [
              { value: null, label: '--Pilih Tim Pemeriksa --' },
            ],
          }
          this.komisionerOption = {
            selected: 'id',
            options: [
              { value: null, label: '--Pilih Nama --' },
            ],
          }
          
          this.dataJadwalSidangPanel = response.data.data
          this.mappingSelectOption(this.dataJadwalSidangPanel.tenaga_ahli, this.tenagaAhliOption.options)
          this.mappingSelectOption(this.dataJadwalSidangPanel.penyaji, this.penyajiOption.options)
          this.mappingSelectOption(this.dataJadwalSidangPanel.tim_pemeriksa, this.timPemeriksaOption.options)
          this.mappingSelectOption(this.dataJadwalSidangPanel.komisioner, this.komisionerOption.options)
        }
      this.$root.$emit('bv::show::modal', `detail_panel_jadwal_${id}`)
    },
    closeEditData(){
      this.showEditData = false
    },
    closeTambahData(){
      this.showTambahData = false
    },
    editData(id) {
      this.showTambahData = false
      this.showEditData = false
      this.instansiId = id
      this.showEditData = true
    },
    async pilihInstansi(id)
    {
      const data = {
        unit_utama_id: id,
        kerjasama_id: this.$route.params.id,
        pihak_ke: this.pihak,

      }
      const response = await pilihUnitUtama(data)
      if(response.data.success === true)
      {
         this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Panel Jadwal',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.refetchData()
            this.reloadDataKerjasama()
      }
    },
    async undang(id, jadwalPembahasanId)
    {
      const data = {
        jadwal_pembahasan_id: jadwalPembahasanId,
        unit_utama_id: id,
      }

      const response = await undangUnitUtama(data)
      if(response.data.success === true)
      {
         this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Panel Jadwal',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: response.data.message,
              },
            })
            this.refetchData()
      }
    },
    reloadDataKerjasama() {
      this.$emit('emitGetDetailDataKerjasama', this.$route.params.id)
    },
    async deleteData(id, nama) 
    {
      if(window.confirm(`Apakah anda yakin ingin menghapus Panel Jadwal ${nama} ?`))
      {
        const data = {
          id: id
        }
        const response = await deletePanelJadwal(data)
        if(response.data.success === true)
        {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Panel Jadwal',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Data berhasil di hapus',
              },
            })
            this.refetchData()
        }
        else
        {
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Panel Jadwal',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: response.data.message,
              },
            })
        }
      }
    },
    async onchangeProvinsi(provinsiId)
    {
      const data = {
        provinsi_id: provinsiId
      }
      this.kotaOptions = {
        selected: 'id',
        options: [
          { value: null, label: '--Pilih Kota --' },
        ],
      }
      const respKota = await getKota(data)
      this.mappingSelectOption(respKota.data, this.kotaOptions.options)
    },
    TambahData()
    {
      if(this.showTambahData === false)
      {
        this.showTambahData = true;
        this.showEditData = false
      }
      else
      {
        this.showTambahData = false;
        this.showEditData = false
      }
    },
    emitAddSuccess(assetId) {
      this.$router.replace(`/Panel Jadwal/edit/${assetId}`)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Panel Jadwal',
              icon: 'CoffeeIcon',
              variant: 'success',
              text: 'Data berhasil di tambahkan',
            },
          })
        })
    },
    mappingSelectOption(master, name) {
      if (master.length > 0) {
        master.forEach(element => {
          name.push({
            label: element.nama,
            value: element.id,
          })
        })
      }
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'jadwal-panel'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const {
      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      dataKerjasama,
      peserta,
    } = useList()

    return {

      // Sidebar
      isAddNewUserSidebarActive,

      fetchDatas,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      dataKerjasama,
      peserta,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
